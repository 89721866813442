.popup-container {
    padding: 40px;
    width: 80vw;
    height: 85vh;
    background-color: var(--c-white);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;

    .MuiAccordion-root {
        background-color: white;
        box-shadow: none;
        border: none;
    }

    .MuiAccordionSummary-expandIconWrapper {
        background-color: transparent;
        border-radius: 50%;
    }

    .MuiAccordionSummary-expandIconWrapper svg path {
        fill: var(--c-black);
    }

    .MuiButtonBase-root.MuiAccordionSummary-root::before {
        display: none;
        border-radius: 10px;
    }

    .MuiButtonBase-root.MuiAccordionSummary-root .MuiTypography-root {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--c-black);
    }

    .header-title {
        font-family: var(--primary-font);
        font-weight: 400;
        font-size: 24px;
        text-transform: uppercase;
        line-height: 38px;
    }

    .sub-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex: 1;
        overflow-y: auto;

        .info-section {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow-y: auto;
            overflow-x: hidden;

            .accordion-section {
                display: flex;
                flex-direction: row;
                gap: 16px;
            }

            .accordion-social {
                display: flex;
                flex-direction: column;
                gap: 16px;
                overflow-y: auto;

                .social-row {
                    display: flex;
                    flex-direction: row;
                    gap: 16px;

                    .social-dropdown {
                        min-width: 125px;
                        max-width: 200px;
                    }
                }
            }
        }

        .profile-picture-section {
            width: 28%;
            flex-direction: column;
            display: flex;
            align-items: center;
            margin-left: 20px;
            background-color: rgba(251, 251, 251, 1);
            border-radius: 10px;

            img {
                border-radius: 50%;
                width: 124px;
                height: 124px;
                border: 4px solid rgb(217, 217, 217);
                margin-top: 40px;
                margin-bottom: 20px;
                object-fit: cover;
            }
        }
    }

    .button-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &:focus {
        outline: none;
    }
}
