@import "../../../assets/scss/partials/mixins";

.custom-stage-select-dropdown {
    min-width: unset !important;
    .MuiSelect-select {
        margin: 0px !important;
        padding: clamp(rem(5), get-vw(7), rem(7)) clamp(rem(15), get-vw(20), rem(20)) !important;
        border-radius: 5px;
        display: flex;
        align-items: center;
        min-height: 1.43px;
        height: 0px !important;
        color: var(--c-white) !important;
    }
    
    .MuiInputBase-input{
        font-family: var(--primary-font) !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0px;
    }
}
