@import "../../assets/scss/partials/mixins";

.change-password-container {
    padding: 40px;
    width: 30vw;
    height: 63vh;
    background-color: var(--c-white);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 576px;

    .header-title {
        font-family: var(--primary-font);
        font-weight: 400;
        font-size: 24px;
        text-transform: uppercase;
        line-height: 38px;
    }

    .close-button {
        text-align: end;
    }

    .password-reset-form {
        margin-bottom: 40px;

        .form-group {
            position: relative;

            .form-control {
                width: 100%;
                height: clamp(rem(46), get-vw(60), rem(60));
                border: 1px solid #cdcdce;
                border-radius: 10px;
                background-color: #fbfbfb;
                outline: transparent;
                box-shadow: none;
            }

            +.form-group {
                margin-top: clamp(rem(20), get-vw(30), rem(30));
            }

            .password-toggle {
                position: absolute;
                width: clamp(rem(46), get-vw(60), rem(60));
                height: clamp(rem(46), get-vw(60), rem(60));
                top: 0;
                right: 0;
                z-index: 2;
                border-radius: 0 10px 10px 0;
                background-color: transparent;
            }

            .dropdown-menu.show {
                display: block;
                position: absolute;
                background-color: white;
                border: 1px solid #ccc;
                width: 100%;
                max-height: 150px;
                overflow-y: auto;
                z-index: 1000;
            }

            .dropdown-item {
                padding: 8px;
                cursor: pointer;

                &:hover {
                    background-color: #f1f1f1;
                }
            }
        }

        .sm-msg {
            margin-top: clamp(rem(18), get-vw(25), rem(25));
            cursor: pointer;

            .inner {
                display: flex;
                align-items: center;
                gap: 6px;
            }
        }

        .submit {
            width: 100%;
            height: clamp(rem(46), get-vw(60), rem(60));
            background-color: var(--c-orange);
            border-radius: 10px;
            margin-top: clamp(rem(30), get-vw(50), rem(50));
            transition: all 0.35s;

            &:hover {
                background-color: var(--c-dark-green);
            }
        }
    }
}