.itinerary-card-outer {
  display: flex;
  border: 1px solid rgb(205, 205, 206);
  border-radius: 10px;
  padding: 10px;
  transition: all 0.5s;
  max-width: 414px;
  min-width: 392px;
  cursor: pointer;

  .itinerary-image {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    object-fit: cover;
    min-width: 80px;
    min-height: 80px;
  }

  .card-details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 16px;
    width: 100%;

    .txt {
      margin-left: 6px;
    }

    .card-header {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }

    .card-details-inner {
      display: flex;
      width: 100%;

      .normal-list {
        width: 50%;
      }
    }
  }

  &:hover {
    border: 1px solid var(--c-orange);
  }
}

.active-card {
  border: 1px solid var(--c-orange);
}
