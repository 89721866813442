@import "../../assets/scss/partials/mixins";

.edit-org-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: clamp(rem(680), get-vw(700), rem(700));
  background-color: var(--c-white);
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 17px 20px;
  display: flex;
  flex-direction: column;

  .MuiAccordionSummary-expandIconWrapper {
    background-color: transparent;
    border-radius: 50%;
  }
  .MuiAccordionSummary-expandIconWrapper svg path {
    fill: var(--c-black);
  }
  .MuiButtonBase-root.MuiAccordionSummary-root::before {
    display: none;
  }
  .MuiButtonBase-root.MuiAccordionSummary-root .MuiTypography-root {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--c-black);
  }

  .close-outer {
    display: flex;
    justify-content:space-between;
    margin-bottom: 10px;

    .header-title {
      font-family: var(--primary-font);
      font-size: 24px;
    }

    span {
      cursor: pointer;
    }
  }

  .form-outer {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, calc(50% - (clamp(rem(15), get-vw(25), rem(25)) / 2))));
    gap: clamp(rem(15), get-vw(25), rem(25));
    margin: 10px 0px;
    &.items-1 {
      grid-template-columns: 1fr;
    }
    &.items-3 {
      grid-template-columns: repeat(3, minmax(auto, calc((100% / 3) - (clamp(rem(30), get-vw(50), rem(50)) / 3))));
      .fw-fg {
        display: inline-flex;
        grid-column-start: 1;
        grid-column-end: 4;
      }
    }
  }

  .footer-outer {
    display: flex;
    justify-content: flex-end;
  }

  &:focus {
    outline: none;
  }
}
