@import "../../../assets/scss/partials/mixins";

.outer-content {
  flex: 1;
  .content-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .lft {
      .lft-inner {
        .view-styles {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: clamp(rem(10), get-vw(15), rem(15)) clamp(rem(15), get-vw(20), rem(20));
          background-color: rgba(205, 205, 206, 0.2);
          gap: clamp(rem(20), get-vw(28), rem(28));
          position: relative;
        }
      }
    }

    .content-right {
      display: flex;
      gap: 14px;

      .filter-outer {
        border-radius: clamp(0.4375rem, 0.5208333333vw, 0.625rem);
        border: 1px solid var(--c-white-gray);
        padding: clamp(0.625rem, 0.78125vw, 0.9375rem) clamp(0.9375rem, 1.0416666667vw, 1.25rem);

        svg {
          cursor: pointer;
        }

        span:last-child {
          margin-left: 24px;
        }
      }
    }
  }
  // .content-bottom::-webkit-scrollbar {
  //   display: none;
  // }

  ::-webkit-scrollbar {
    height: 5px;
  }
  .content-bottom-grab {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  .content-bottom {
    width: 100%;
    overflow-x: auto;
    margin-top: clamp(rem(20), get-vw(32), rem(32));
    min-height: 80vh;
    padding-bottom: 10px;

    .cb-inner {
      display: flex;
      gap: clamp(rem(10), get-vw(15), rem(15));
      flex-wrap: nowrap;
      flex-direction: row;
      .cb-inner-row {
        min-width: clamp(rem(260), get-vw(300), rem(300));
        overflow-x: hidden;
        .cb-inner-row-top {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: clamp(rem(10), get-vw(15), rem(15)) clamp(rem(15), get-vw(20), rem(20));
          border: 2px solid rgba(205, 205, 206, 0.4);
          border-radius: clamp(rem(7), get-vw(10), rem(10)) clamp(rem(7), get-vw(10), rem(10)) 0 0;
          margin-bottom: clamp(rem(10), get-vw(12), rem(12));
          .count {
            padding: 4px 8px;
            background-color: var(--c-dark-green);
            color: var(--c-white);
            border-radius: 5px;
          }

          &.bdc-yellow {
            border-bottom-color: var(--c-yellow);
          }
          &.bdc-orange {
            border-bottom-color: var(--c-orange);
          }
          &.bdc-light-green {
            border-bottom-color: var(--c-light-green);
          }
          &.bdc-dark-green {
            border-bottom-color: var(--c-dark-green);
          }
        }
      }
    }
  }
}

.lead-card-outer {
  height: 70vh;
  overflow-y: auto;
  .lead-card {
    width: 100%;
    padding: clamp(rem(15), get-vw(20), rem(20));
    background-color: rgba(245, 245, 245, 0.4);
    border: 1px solid rgba(205, 205, 206, 0.4);
    .top {
      cursor: pointer;
      .name {
        display: block;
        text-align: left;
        margin-bottom: clamp(rem(8), get-vw(12), rem(12));
      }
    }
    .lead-icon-list {
      .lead-icon-item {
        display: flex;
        align-items: center;
        gap: clamp(rem(3), get-vw(5), rem(5));
        .icon {
          img {
            width: clamp(rem(11), get-vw(12), rem(12));
          }
        }
        .txt {
          font-size: clamp(rem(14), get-vw(16), rem(16));
        }
        + .lead-icon-item {
          margin-top: clamp(rem(1), get-vw(4), rem(4));
        }
      }
    }
    hr {
      margin: clamp(rem(10), get-vw(12), rem(12)) 0;
      border-color: rgba(205, 205, 206, 0.4);
      opacity: 1;
    }
    .cricle-btn-nav {
      gap: clamp(rem(3), get-vw(5), rem(5));
      .circle-item {
        display: flex;
        .referral-icon {
          width: 24px;
          height: 24px;
        }
        .MuiAvatar-root {
          width: 23px;
          height: 23px;
          font-size: 10px;
        }
        .circle-btn {
          transition: all 0.35s;
          &:hover {
            transform: scaleX(-1);
          }
        }
      }
    }

    .avatar-container {
      position: relative;

      .cancel-btn {
        font-size: 12px;
        position: absolute;
        top: -2px;
        right: -2px;
      }
    }
    
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .date {
        display: inline-block;
        padding: 5px 8px 5px;
      }

      .expand-label {
        font-size: 8px;
        background-color: rgb(37, 28, 21, 0.5);
        padding: 5px 8px 5px;
        border-radius: 5px;

        &.expand-inactive {
          display: none;
        }
      }
    }
    + .lead-card {
      margin-top: clamp(rem(12), get-vw(15), rem(15));
    }
    &:hover {
      .bottom {
        .rht {
          .date {
            display: none;
          }
          .expand-label {
            display: inline-block;
          }
        }
      }
    }
  }
}
