.button-container {
    background-color: rgba(245, 227, 215, 1);
    border-radius: 21.5px;
    padding: 4px 12px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    span {
        font-weight: 500;
        font-family: var(--primary-font);
        font-size: 14px;
        text-transform: uppercase;
        color: var(--c-orange);
    }
}