.builder-map-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .location-list {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding: 10px 0px;

        .selected-location {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: var(--primary-font);

            .selected-location-inner {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        .selected-location + .selected-location {
            padding-top: 12px;
        }
    }

    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--primary-font);
        background-color: #d9d9d9;
        width: 330px;
        height: 50%;

        img {
            width: 100%;
        }

        // .gm-bundled-control-on-bottom {
        //     bottom: 70px !important;
        //     right: 25px !important;

        //     div {
        //         background-color: transparent !important;
        //     }

        //     .gm-control-active {
        //         width: 30px !important;
        //         height: 30px !important;

        //         img {
        //             width: 10px !important;
        //             height: 10px !important;
        //         }
        //     }
        // }
    }

    .footer-outer {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0px;
    }
}
