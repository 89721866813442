.file-table {
    width: 100%;
    border-radius: 8px;
    overflow: auto;
    background-color: var(--c-white);
    .file-table-header,
    .file-table-row {
        display: grid;
        grid-template-columns: 1px 1fr 80px 80px 120px 60px;
        align-items: center;
        padding: 12px 16px;
        border-bottom: 1px solid #e0e0e0;
        .file-column {
            display: flex;
            align-items: center;
            font-family: var(--body-font);
            font-weight: 400;
            font-size: 14px;
            line-height: 16.84px;
            .action-icon {
                margin-left: 12px;
                cursor: pointer;
                color: var(--c-black-gray);
            }
            .action-icon:hover {
                color: var(--c-black);
            }
        }
        .file-column-header {
            display: flex;
            align-items: center;
            font-family: var(--body-font);
            font-weight: 600;
            font-size: 12px;
            line-height: 14.44px;
            text-transform: uppercase;
        }
        input[type="checkbox"] {
            margin-right: 10px;
            appearance: none;
            width: 15px;
            height: 15px;
            border: 2px solid #000 !important;
            border-radius: 3px;
            padding: 0px !important;
        }
        input[type="checkbox"]:checked {
            background-color: #000 !important;
            border-color: #000 !important;
        }
    }
    .file-table-row:hover {
        background-color: #f5f5f5;
    }
}
.no-files {
    text-align: center;
    span {
        font-family: var(--body-font);
        font-weight: 600;
        size: 20px;
    }
}