.main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 1.5vw;
    margin-top: 20px;
    max-height: 93%;

    .sub-container {
        flex: 1;
        // width: 70%;
        max-height: 100%;
        overflow-y: auto;

        .container-title {
            position: absolute;
            background-color: white;
            font-family: var(--primary-font);
            font-weight: 400;
            font-size: 18px;
            text-transform: uppercase;
            z-index: 100;
            margin-left: 30px;
            top: -0.05em;
            padding: 0 10px;
        }

        .second-sub-container {
            width: 100%;
            position: relative;
            padding-top: 10px;
        }

        // Profile Section
        .profile-section {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            width: 100%;

            .personal-info {
                background: var(--c-white);
                border: 1px solid var(--c-gray);
                border-radius: 10px;
                padding: 26px;
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: start;
                width: 100%;

                .profile-image {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: start;
                    margin-bottom: 20px;
                    gap: 22px;

                    img {
                        border-radius: 50%;
                        width: 124px;
                        height: 124px;
                        border: 4px solid rgb(217, 217, 217);
                    }
                }


                .info-section {
                    display: flex;
                    flex-direction: column;
                    margin-left: 38px;
                    width: 100%;

                    .title-container {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        h3 {
                            font-size: 10px;
                            color: var(--c-black-gray);
                            font-weight: 600;
                        }

                        .line {
                            flex: 1;
                            border: none;
                            border-top: 1px solid var(--c-gray);
                            margin-right: 10px;
                        }
                    }



                    .header-group {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .header-text {
                            font-family: var(--primary-font);
                            font-weight: 400;
                            font-size: 30px;
                            line-height: 48px;
                        }
                    }

                    .info-group {
                        width: 100%;
                        display: flex;
                        flex-direction: row;

                        .info-block {
                            display: flex;
                            flex-direction: column;
                            min-width: 15vw;
                            width: 5vw;
                            max-width: 20vw;
                            padding: 10px;

                            .title {
                                font-family: var(--body-font);
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 14.44px;
                                text-transform: uppercase;
                            }

                            .value {
                                font-family: var(--primary-font);
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 25.5px;
                                color: black;
                            }
                        }
                    }
                }
            }


        }

        // Password Section
        .password-section {
            background: var(--c-white);
            border: 1px solid var(--c-gray);
            border-radius: 10px;
            padding: 20px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .detail-box {
                max-width: 500px;
                font-family: var(--primary-font);
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: var(--c-gray);

            }


        }

        // Documents Section
        .documents-section {
            background: var(--c-white);
            border: 1px solid var(--c-gray);
            border-radius: 10px;
            padding: 20px;
            margin-bottom: 30px;

            .upload-btn {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                .title {
                    font-family: var(--primary-font);
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 24px;
                    color: var(--c-black);
                    text-transform: uppercase;
                }
            }

            .documents-container {
                display: flex;
                flex-direction: column;

                .document-list {
                    display: flex;
                    // flex-wrap: wrap;
                    justify-content: space-between;
                    overflow-x: auto;
                    gap: 10px;
                }

                .uploaded-list {
                    gap: 30px;
                    margin-top: 40px;

                    .files-header {
                        font-family: var(--primary-font);
                        size: 15px;
                        line-height: 24px;
                        font-weight: 400;
                        text-transform: uppercase;
                    }
                }
            }

        }
    }

}