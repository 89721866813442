@import "../../assets/scss/partials/mixins";

.border-btn {
    padding: clamp(rem(7), get-vw(10), rem(10));
    border-radius: clamp(rem(15), get-vw(20), rem(20));
    background-color: var(--c-light-orange);
    font-weight: 500;
    position: relative;
    z-index: 2;
    svg {
        path,
        rect {
            fill: var(--c-orange) !important;
        }
    }

    &:hover {
        .icon {
            svg {
                path,
                rect {
                    fill: white !important;
                }
            }
        }
        background-color: var(--c-orange);
    }
}
.content-btn {
    padding: clamp(rem(7), get-vw(10), rem(10));
    background-color: var(--c-orange);
    border-radius: clamp(rem(15), get-vw(20), rem(20));
    font-weight: 500;
    position: relative;
    z-index: 2;

    span {
        color: white !important;
    }

    svg {
        path,
        rect {
            fill: white !important;
        }
    }

    &:hover {
        svg {
            path,
            rect {
                fill: var(--c-orange) !important;
            }
        }

        span {
            color: var(--c-orange) !important;
        }
        background-color: var(--c-light-orange) !important;
    }
}

.red-border-btn {
    padding: clamp(rem(7), get-vw(10), rem(10));
    border-radius: clamp(rem(15), get-vw(20), rem(20));
    background-color: rgba($color: red, $alpha: 0.2);
    border: 1px red solid !important;
    font-weight: 500;
    position: relative;
    z-index: 2;
    svg {
        path,
        rect {
            fill: red !important;
        }
    }
    span{
        color: red !important;
    }

    &:hover {
        .icon {
            svg {
                path,
                rect {
                    fill: white !important;
                }
            }
        }
        span{
            color: white !important;
        }
        background-color: red !important;
    }
}
.red-content-btn {
    padding: clamp(rem(7), get-vw(10), rem(10));
    background-color: red;
    border-radius: clamp(rem(15), get-vw(20), rem(20));
    font-weight: 500;
    position: relative;
    z-index: 2;

    span {
        color: white !important;
    }

    svg {
        path,
        rect {
            fill: white !important;
        }
    }

    &:hover {
        svg {
            path,
            rect {
                fill: red !important;
            }
        }

        span {
            color: red !important;
        }
        border: 1px red solid !important;
        background-color: rgba($color: red, $alpha: 0.2) !important;
    }
}
