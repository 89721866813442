@import "../../assets/scss/partials/mixins";

.exp-modal-inner {
  width: 85vw;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
  padding: clamp(rem(40), get-vw(80), rem(80));
  .gb-head {
    display: flex;
    align-items: center;
    gap: clamp(rem(20), get-vw(30), rem(30));

    .go-back-arrow {
      transition: all 0.35s;
      cursor: pointer;
      &:hover {
        transform: rotate(180deg);
      }
    }
  }
  .middle {
    display: flex;
    flex-wrap: wrap;
    margin: clamp(rem(30), get-vw(60), rem(60)) 0;
    .lft {
      width: clamp(rem(260), get-vw(320), rem(320));
      padding-right: clamp(rem(40), get-vw(80), rem(80));
      .num-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        .txt {
          width: calc(100% - clamp(rem(50), get-vw(60), rem(60)));
          color: rgba(37, 28, 21, 0.3);
          padding-right: clamp(rem(28), get-vw(34), rem(34));
          text-align: right;
          transition: color 0.35s;
          .lbl {
            margin-bottom: clamp(rem(1), get-vw(3), rem(3));
          }
        }
        .number {
          width: clamp(rem(50), get-vw(60), rem(60));
          position: relative;
          --current-color: rgba(37, 28, 21, 0.3);
          .num {
            display: grid;
            width: 100%;
            aspect-ratio: 1/1;
            place-items: center;
            border: 2px solid rgba(37, 28, 21, 0.3);
            border-radius: 50%;
            font-size: clamp(rem(17), get-vw(22), rem(22));
            color: rgba(37, 28, 21, 0.3);
            --current-color: rgba(37, 28, 21, 0.3);
            transition: all 0.35s;
          }
          &::before {
            content: "";
            width: 1px;
            height: calc(10vh - (clamp(rem(10), get-vw(20), rem(20)) + clamp(rem(5), get-vw(5), rem(5))));
            border-right: 1px dashed var(--current-color);
            position: absolute;
            right: 0;
            left: 0;
            margin: auto;
            bottom: calc(-10vh + (clamp(rem(10), get-vw(20), rem(20)) - clamp(rem(5), get-vw(5), rem(5))));
          }
          &::after {
            content: "";
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: clamp(rem(4), get-vw(6), rem(6)) clamp(rem(3), get-vw(4), rem(4)) 0
              clamp(rem(3), get-vw(4), rem(4));
            border-color: var(--current-color) transparent transparent;
            transform: rotate(0deg);
            position: absolute;
            right: 0;
            left: 0;
            margin: auto;
            bottom: calc(-10vh + (clamp(rem(10), get-vw(20), rem(20)) - clamp(rem(5), get-vw(5), rem(5))));
          }
        }
        + .num-item {
          margin-top: 10vh;
        }
        &:hover {
          .txt {
            color: var(--c-black-gray);
            --current-color: #251c15;
            --current-color-rgb: rgb(37, 28, 21);
          }
          .number {
            .num {
              color: var(--c-black-gray);
              --current-color: #251c15;
              --current-color-rgb: rgb(37, 28, 21);
              border-color: var(--c-black-gray);
            }
          }
        }
        &.active {
          .txt {
            color: var(--c-dark-green);
            --current-color: #134b5f;
            --current-color-rgb: rgb(19, 75, 95);
          }
          .number {
            .num {
              color: var(--c-dark-green);
              --current-color: #134b5f;
              --current-color-rgb: rgb(19, 75, 95);
              border-color: var(--c-dark-green);
            }
          }
        }
        &.compleated {
          .txt {
            color: var(--c-dark-green);
            --current-color: #134b5f;
            --current-color-rgb: rgb(19, 75, 95);
          }
          .number {
            .num {
              color: var(--c-white);
              --current-color: #fff;
              --current-color-rgb: rgb(255, 255, 255);
              border-color: var(--c-dark-green);
              background-color: var(--c-dark-green);
            }
          }
        }
        &:last-child {
          .number {
            &::after,
            &::before {
              display: none;
            }
          }
        }
      }
    }
    .rht {
      padding: clamp(rem(30), get-vw(40), rem(40)) clamp(rem(15), get-vw(20), rem(20));
      background-color: #f5f5f5;
      width: calc(100% - clamp(rem(260), get-vw(320), rem(320)));
      border-radius: clamp(rem(7), get-vw(10), rem(10));

      .lead-rht-inner {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .scroll-div {
          overflow-y: auto;
          height: 45vh;
          height: 45dvh;
          padding: 0 clamp(rem(15), get-vw(20), rem(20));
        }

        .itinerary-outer {
          display: flex;
          flex-direction: row;

          .itinerary-inner {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-right: 20px;
            overflow-y: auto;

            .dropdown-container {
              margin-right: 20px;
            }

            .itinerary-scroll {
              display: flex;
              flex-direction: column;
              flex: 1;
              overflow-y: auto;
              gap: 10px;

              .itinerary-card-outer {
                margin-right: 20px;
              }
            }
          }

          .itinerary-map {
            flex: 0.8;
            display: flex;

            .pdf-viewer {
              flex: 1;
            }

            .empty-pdf-view {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              background-color: var(--c-white);
              border-radius: 10px;

              img {
                width: 100px;
                height: 100px;
              }

              .header {
                text-align: center;
                font-size: 1.6vw;
                margin: 30px 0px
              }

              .description{
                font-size: 14px;
                width: 80%;
                text-align: center;
              }
            }
          }
        }
      }

      .header {
        margin-bottom: clamp(rem(30), get-vw(40), rem(40));
        line-height: 1;
      }

      .arrival-header {
        margin-bottom: clamp(rem(15), get-vw(30), rem(30));
        line-height: 1;
      }

      .header-button {
        display: flex;
        flex-direction: row;
        margin-bottom: clamp(rem(30), get-vw(40), rem(40));
        justify-content: space-between;
        align-items: center;
        padding: clamp(rem(10), get-vw(15), rem(15)) clamp(rem(15), get-vw(20), rem(20));
        background-color: rgba(205, 205, 206, 0.2);
        gap: clamp(rem(20), get-vw(28), rem(28));
        position: relative;
        width: min-content;
      }

      .small-label {
        font-size: 14px;
        display: block;
        margin-top: -10px;
        margin-bottom: 12px;
        font-weight: 500;
      }
      .form-outer {
        display: grid;
        grid-template-columns: repeat(2, minmax(auto, calc(50% - (clamp(rem(15), get-vw(25), rem(25)) / 2))));
        gap: clamp(rem(15), get-vw(25), rem(25));
        &.items-1 {
          grid-template-columns: 1fr;
        }
        &.items-3 {
          grid-template-columns: repeat(3, minmax(auto, calc((100% / 3) - (clamp(rem(30), get-vw(50), rem(50)) / 3))));
          .fw-fg {
            display: inline-flex;
            grid-column-start: 1;
            grid-column-end: 4;
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    > * {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: clamp(rem(15), get-vw(25), rem(25));
    }
  }
}
