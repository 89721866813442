.drp-search-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;

    .search-container {
        background: var(--c-white);
        width: 300px;
        border-radius: 8px !important;
        padding: 0px 12px 0px 0px !important;
        margin-bottom: 10px;

        .search-icon {
            font-size: 18px;
            margin: 0px 10px;
        }

        input {
            height: 42px;
        }

        .img-container {
            margin: 0px !important;

            .img {
                margin: 14px 17px 13px 19px;
                width: 24px;
                height: 24px;
            }
        }

        .input {
            font-size: 14px;
            font-weight: 500;
            color: black;
            padding: 0px !important;
        }
    }

    .item {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        padding: 9px 49px 9px 10px;
        border-radius: 8px;
        margin-right: 4px;

        .avatar-container {
            position: relative;

            .cancel-btn {
                display: none !important;
                font-size: 12px;
                position: absolute;
                top: -3px;
                right: -3px;
            }
        }

        span {
            margin-left: 10px;
        }

        &:hover {
            color: var(--c-white);
            background-color: var(--c-orange);
            opacity: 1;

            .cancel-btn {
                display: block !important;
            }
        }
    }
}
