.dropdown-container {
    position: relative;

    .dropdown-header {
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #ccc;
        box-sizing: border-box;
    }

    .dropdown-icon {
        height: 42px;
        min-width: 39px;
        margin-left: 1px;
        background-color: #e0e6ea;
        border-radius: 9px 0px 0px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-input {
        width: 100%;
        margin: 10px;
        border: none;
        outline: none;
        flex-grow: 1;
        font-size: 16px;
        background-color: transparent;
    }

    .dropdown-value {
        margin: 10px;
        flex-grow: 1;
        font-size: 16px;
        font-family: var(--primary-font);
        text-align: start;
        cursor: pointer;
    }

    .dropdown-arrow {
        margin-right: 13px;
        cursor: pointer;

        &.rotate {
            background-color: red;
        }
    }

    .dropdown-menu-content {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        max-height: 200px;
        overflow-y: auto;

        .dropdown-item {
            padding: 8px;
            font-family: var(--primary-font);
            font-size: 14px;
            cursor: pointer;
            &:hover {
                background-color: #f1f1f1;
            }
        }
    }
}
