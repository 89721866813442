.tabs-selector {
    text-align: center;
    max-width: 400px;
    margin: 40px;

    .emoji-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2;
        margin-top: 2;
    }
}