.custom-phone-field {
    .MuiInputBase-root {
        height: 34px;
        padding-left: 4px !important;
    }

    .MuiSelect-select {
        padding: 8px;
        padding-right: 32px !important;
        
        .react-international-phone-flag-emoji{
            height: 20px;
        }
    }
}
