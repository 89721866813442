.template-outer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    overflow-y: auto;
    max-height: calc(100vh - 240px);

    .header-outer{
        width: 100%;
        padding-top: 10px;
    }
}
