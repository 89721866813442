@import "../../assets/scss/partials/mixins";

.exp-modal-inner {
  .top-middle {
    display: flex;
    flex-direction: column;
    height: 100%;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      .rht {
        padding-left: clamp(rem(15), get-vw(25), rem(25));
        border: 1px solid var(--c-gray);
        border-radius: clamp(rem(7), get-vw(10), rem(10));
      }
    }

    .card-view-inner-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin: clamp(rem(20), get-vw(30), rem(30)) 0 0;
      .card-details {
        flex: 0 0 auto;
        width: calc(100% - clamp(rem(380), get-vw(540), rem(540)));
        .top {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          gap: 0 20px;

          .right {
            display: flex;
            flex-direction: row;

            .label {
              .label-btn {
                padding: clamp(rem(5), get-vw(7), rem(7)) clamp(rem(15), get-vw(20), rem(20));
                background-color: var(--c-orange);
                color: var(--c-white);
                border-radius: 5px;
              }
            }

            .assign-user {
              display: flex;
              justify-content: end;
              width: 40px;
              cursor: pointer;
              margin-left: 22px;

              .MuiAvatar-root {
                width: 23px;
                height: 23px;
                font-size: 10px;
              }
            }
          }
        }
      }

      .card-status {
        flex: 0 0 auto;
        width: clamp(rem(350), get-vw(500), rem(500));
        .top {
          .left {
            .view-styles {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: clamp(rem(10), get-vw(15), rem(15)) clamp(rem(15), get-vw(20), rem(20));
              background-color: rgba(205, 205, 206, 0.2);
              gap: clamp(rem(20), get-vw(28), rem(28));
              position: relative;
              .transparent-btn {
                display: flex;
                align-items: center;
                line-height: 1;
                gap: 6px;
                .icon {
                  svg {
                    width: clamp(rem(16), get-vw(20), rem(20));
                    height: clamp(rem(16), get-vw(20), rem(20));
                    path,
                    rect {
                      fill: var(--c-dark-green);
                      transition: all 0.5s ease;
                    }
                  }
                }
                .txt {
                  line-height: 0;
                  color: var(--c-dark-green);
                  --current-color: #134b5f;
                  --current-color-rgb: rgb(19, 75, 95);
                  transition: all 0.5s ease;
                }
                &.btn-1 {
                  position: relative;
                  &::after {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 1px;
                    right: clamp(rem(-14), get-vw(-14), rem(-10));
                    top: 0;
                    background-color: var(--c-gray);
                  }
                }
                &:hover,
                &.active {
                  .icon {
                    svg {
                      path,
                      rect {
                        fill: var(--c-orange);
                      }
                    }
                  }
                  .txt {
                    color: var(--c-orange);
                    --current-color: #e27c37;
                    --current-color-rgb: rgb(226, 124, 55);
                  }
                }
              }
            }
          }
        }
      }
    }
    .card-view-inner-bottom {
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      margin: clamp(rem(20), get-vw(30), rem(30)) 0 0;

      .info-sec {
        flex: 0 0 auto;
        width: calc(100% - clamp(rem(380), get-vw(540), rem(540)));
        padding: 20px;
        border: 1px solid var(--c-gray);
        max-height: 100%;
        overflow-y: auto;

        .MuiTableCell-root-container {
          display: flex;
          align-items: center;
          .user-header-icon {
            margin-right: 12px;
            position: relative;

            .MuiAvatar-root {
              height: 21px;
              width: 21px;
              font-size: 10px;
            }
          }
        }

        .task-row .task-delete {
          font-size: 20px;
          transition: all 0.5s ease;
        }

        .task-row:hover .task-delete {
          display: inline-block;
          color: #d32f2f;
        }
        .c-pointer {
          cursor: pointer;
        }

        .tab-outer-sec {
          .edit-lead {
            cursor: pointer;
            color: var(--c-orange);
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
              cursor: pointer;
            }
          }

          .MuiBox-root {
            padding: 0;
          }
          .MuiPaper-root {
            border-radius: 0 !important;

            .task-delete {
              font-size: px;
            }
          }

          .MuiButtonBase-root {
            padding: 0px;
          }

          .MuiFormControlLabel-root {
            margin: 0px;
          }
        }
      }
      .status-sec {
        flex: 0 0 auto;
        width: clamp(rem(350), get-vw(500), rem(500));
        padding: clamp(rem(15), get-vw(25), rem(25));
        background-color: #fbfbfb;
        max-height: 100%;
        height: 100%;
        overflow-y: auto;
        .state {
          display: flex;
          align-items: start;
          margin-bottom: 10px;

          .log-content {
            width: 100%;
          }

          .MuiSvgIcon-root {
            font-size: 6px;
            margin-top: 6px;
            margin-right: 10px;
          }

          .date-time {
            margin-top: 3px;
          }

          + .state {
            margin-top: clamp(rem(3), get-vw(5), rem(5));
          }
        }
      }
    }
  }

  .accordian-inner {
    &.accordian-4-col-inner {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: clamp(rem(20), get-vw(25), rem(25)) clamp(rem(10), get-vw(15), rem(15));
      .accordian-info-item {
        flex: 0 0 auto;
        width: calc((25% - clamp(rem(20), get-vw(25), rem(25))) + (clamp(rem(20), get-vw(25), rem(25)) / 2));
        &.fw-100 {
          width: 100%;
        }
        .s-label {
          font-size: clamp(rem(11), get-vw(12), rem(12));
          text-transform: uppercase;
          margin-bottom: 5px;
          font-family: var(--body-font);
          font-weight: 600;
        }
        .info-txt {
          font-size: clamp(rem(14), get-vw(16), rem(16));
          font-family: var(--primary-font);
        }
      }
    }
  }

  span.icon-txt {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: var(--primary-font);
    text-transform: uppercase;
    &.urgent {
      color: #cc4b4c;
      svg {
        path {
          fill: #cc4b4c;
        }
      }
    }
    &.normal {
      color: var(--c-light-green);
      svg {
        path {
          fill: var(--c-light-green);
        }
      }
    }
    &.date {
      font-family: var(--primary-font);
      svg {
        path {
          fill: var(--c-dark-green);
        }
      }
    }
  }

  .itinerary-data {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 24px;
    overflow-x: hidden;

    .submitted-itineraries {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}
