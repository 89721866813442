.custom-calender {
    .calender-header {
        display: flex;
        justify-content: space-between;
        padding-bottom: clamp(1.625rem, 1.6666666667vw, 2rem);
        .date-content {
            display: flex;
            align-items: center;
            .button-today {
                text-transform: uppercase;
                font-size: 16px;
                font-family: var(--primary-font);
                padding: clamp(0.625rem, 0.78125vw, 0.9375rem) clamp(0.9375rem, 1.0416666667vw, 1.25rem);
                border-radius: 10px;
                border: 1px solid #cdcdce;
                transition: all 0.15s;

                &:hover {
                    border: 1px solid black;
                }
            }

            .arrow-icon {
                font-size: 18px;
                cursor: pointer;
            }

            .calender-date {
                width: 114px;
                text-align: center;
                text-transform: uppercase;
                font-family: var(--primary-font);
                font-size: 18px;
            }
        }

        .filter-content {
            display: flex;
            gap: 14px;

            .filter-outer {
                border-radius: clamp(0.4375rem, 0.5208333333vw, 0.625rem);
                border: 1px solid var(--c-white-gray);
                padding: clamp(0.625rem, 0.78125vw, 0.9375rem) clamp(0.9375rem, 1.0416666667vw, 1.25rem);

                svg {
                    cursor: pointer;
                }

                span:last-child {
                    margin-left: 24px;
                }
            }

            .drop-down {
                border-radius: 10px;
                .MuiInputBase-input {
                    padding: clamp(0.625rem, 0.88125vw, 0.9375rem) clamp(0.9375rem, 1.0416666667vw, 1.25rem);
                    margin-right: 14px;
                    font-family: var(--primary-font) !important;
                    text-transform: uppercase;
                    color: var(--c-orange) !important;
                }

                .MuiSvgIcon-root {
                    margin-left: 10px;
                    color: var(--c-orange);
                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: var(--c-orange);
                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: #cdcdce;
                }

                &:hover {
                    .MuiOutlinedInput-notchedOutline {
                        border-color: var(--c-orange);
                    }
                }
            }
            .drop-down.Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border-color: var(--c-orange) !important;
                }
            }
        }
    }

    .container {
        max-width: initial;
        height: 78vh !important;

        .toastui-calendar-layout {
            .toastui-calendar-daygrid-cell {
                .toastui-calendar-grid-cell-header {
                    display: flex;
                    justify-content: end;

                    .toastui-calendar-weekday-grid-date {
                        font-size: 14px;
                    }

                    .toastui-calendar-grid-cell-date {
                        margin: 5px;
                        .toastui-calendar-weekday-grid-date-decorator {
                            background-color: var(--c-orange);
                            color: var(--c-white);
                            border: 1px solid var(--c-white);
                            border-radius: 5px;
                        }
                    }
                }
            }

            .toastui-calendar-day-names {
                padding: 0px;
                .toastui-calendar-template-monthDayName {
                    display: flex;
                    justify-content: center;
                    text-transform: uppercase;
                    font-family: "Glancyr";
                }
            }
        }
        .toastui-calendar-icon {
            display: none;
        }
        // .toastui-calendar-section-detail {
        //     display: none;
        // }

        .toastui-calendar-detail-container {
            cursor: pointer;
            padding: 14px 18px;
            width: max-content;
            height: 94px;
        }

        .popup-details-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .popup-details-header-title {
                text-transform: capitalize;
                font-weight: 400;
                font-family: var(--primary-font);
                font-size: 16px;
            }
            .popup-details-header-stage {
                font-family: var(--body-font);
                font-size: 14px;
                font-weight: 400;
                margin-left: 14px;
            }
        }

        .popup-details-body {
            display: flex;
            flex-direction: column;

            .popup-details-body-task {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .popup-details-body-task-name {
                    text-transform: uppercase;
                    font-family: var(--primary-font);
                    font-weight: 500;
                    font-size: 10px;
                }

                .popup-details-body-task-stage {
                    font-family: var(--primary-font);
                    font-weight: 400;
                    font-size: 10px;
                    color: white;
                    padding: 0px 6px;
                    border-radius: 8px;
                    margin-left: 14px;
                }
            }
            .popup-details-body-task-date {
                text-transform: uppercase;
                line-height: 10px;
                font-size: 9px;
            }
        }

        .toastui-calendar-popup-top-line {
            position: absolute;
            left: 0px;
        }
    }
}
