.custom-select-field {

    .MuiSelect-outlined {
        padding: 5.4px 14px !important;
    }

    .dropdown-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;

        span {
            font-family: var(--body-font);
            font-size: 14px;
            font-weight: 400;
        }
    }
}