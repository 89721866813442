.file-container {
    padding: 16px;
    display: flex;
    flex-direction: row;
    min-width: 300px;
    max-width: 500px;
    background: var(--c-white);
    border: 1px solid rgba(236, 236, 236, 1);
    gap: 16px;
    border-radius: 5px;


    .pdf-icon {
        height: 48px;
        width: 48px;
        background-color: rgba(255, 217, 218, 1);
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .doc-icon {
        height: 48px;
        width: 48px;
        background-color: rgba(213, 233, 255, 1);
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .txt-icon {
        height: 48px;
        width: 48px;
        background-color: rgba(224, 224, 224, 1);
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .details-column {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .file-name {
            font-family: var(--body-font);
            font-weight: 400;
            font-size: 14px;
            line-height: 16.84px;
            color: rgba(0, 0, 0, 1);
        }

        .date-size {
            display: flex;
            flex-direction: row;
            gap: 16px;

            .date {
                font-family: var(--body-font);
                font-weight: 400;
                font-size: 12px;
                line-height: 14.44px;
                color: var(--c-gray);
            }
        }

    }
}